import React, { Fragment } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import './404.css'
import Ops from '../assets/icons/all/ops.svg'
import Pagina from '../components/Pagina'

const NotFoundPage = () => {
  const data = useStaticQuery(
    graphql`
    query {
      pagina:contentfulPagina(path: { eq: "/404/" }) {
        path
        metaTitle
        metaDescription
        tags
      }
    }
  `
  )
  return (
    <Fragment>
      <Pagina pagina={data.pagina}/>
      <div id="m404">
        <div className="container">
          <div className="row my-5">
            <div className="col-12 d-flex flex-column justify-content-center align-items-center">
              <div className="w-100 title position-relative">
                <div className="w-100"></div>
                <h2>ERRO 404</h2>
              </div>
              <img src={Ops} alt="Ops" className="mt-4" />
              <p className="p-0 m-0">OOPS</p>
              <h1 className="position-relative">Página não encontrada.</h1>
              <Link to="/" tile="" className="py-2 mt-3 w-100 btn theme-border-2 theme--secundary text-white">IR PARA A HOME DO SITE</Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default NotFoundPage
